import i18n from '@/utils/lang'
import store from '@/store'
import { deleteCookie } from '@common/cookie'
import { singleSignOnURL, singleSignOutURL } from '@/config/config'
import { getRouter } from '@/main'

function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

function copy(data) {
  const url = data
  const oInput = document.createElement('input')
  oInput.value = url
  document.body.appendChild(oInput)
  oInput.select() // 选择对象;
  document.execCommand('Copy') // 执行浏览器复制命令
  layer.msg(i18n.t('已复制'), { timeout: 800 })
  oInput.remove()
}

function getPermission(keyList = []) {
  let userSession = localStorage.getItem('userSession')
  if (userSession) {
    userSession = JSON.parse(userSession)
  }
  let funcList = userSession.funcList
  keyList.forEach((item, index) => {
    funcList = funcList?.find((elem) => elem.code === item)
    if (index < keyList.length - 1) {
      funcList = funcList?.children || []
    }
  })
  return funcList !== undefined
}

function toFixedUp(value, decimalPlaces) {
  const factor = Math.pow(10, decimalPlaces)
  return (Math.ceil(value * factor) / factor).toFixed(decimalPlaces)
}

/**
 *
 * @param {Array} list 单位列表
 * @param {Object} targetUnit 指定单位
 * @param {Object} baseUnit 被转换单位
 */
const getUnitRate = ({ list, targetUnit, baseUnit }) => {
  if (list && !baseUnit) {
    baseUnit = list.find((item) => item.baseUnit) || {}
  }
  let rate = 1,
    unit = baseUnit.unitName,
    alias = baseUnit.unitNameFull
  if (targetUnit) {
    rate = targetUnit.conversionRate / baseUnit.conversionRate
    unit = targetUnit.unitName
    alias = targetUnit.unitNameFull
  }
  return {
    rate,
    unit,
    alias,
    base_unit: baseUnit.unit,
  }
}

function convertKeysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item))
  }

  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const result = {}
  Object.keys(obj).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
    result[camelCaseKey] = convertKeysToCamelCase(obj[key])
  })

  return result
}

//将字符串空格分分隔成逗号分隔
const spaceToComma = (str = '') => {
  return str
    .trim()
    .split(' ')
    .filter((item) => item)
    .join(',')
}

function logout() {
  deleteCookie()
  // let defaultBu = localStorage.getItem('defaultBu')
  localStorage.clear()
  // localStorage.setItem('defaultBu', defaultBu)
  localStorage.setItem('pageCacheFullPath', window.location.pathname + window.location.search)
  window.location.href = singleSignOutURL + '?redirect_uri=' + encodeURIComponent(singleSignOnURL)
}

function buildTree(nodes) {
  const map = {}
  nodes.forEach((item) => {
    item.title = item.name
    item.key = item.code
    map[item.code] = item
  })
  const tree = []
  nodes.forEach((item) => {
    const parent = map[item.parent_code]
    if (parent) {
      if (!parent.children) {
        parent.children = []
        parent.childrenCodeList = []
      }
      item.key = item.parent_code + '-' + item.code
      parent.children.push(item)
      parent.childrenCodeList.push(item.code)
    } else {
      tree.push(item)
    }
  })
  return tree
}

const buildTree2 = (data) => {
  // 创建一个对象来存储每个节点的引用
  const nodeMap = {}
  // 创建一个数组来存储根节点
  const roots = []

  // 首先将所有节点存储在 nodeMap 中
  data.forEach((item) => {
    nodeMap[item.code] = {
      key: item.code,
      title: item.name,
      children: [],
    }
  })

  // 然后遍历每个节点，将其添加到其父节点的 children 数组中
  data.forEach((item) => {
    const node = nodeMap[item.code]
    if (item.parent_code) {
      const parent = nodeMap[item.parent_code]
      parent.children.push(node)
    } else {
      roots.push(node)
    }
  })

  return {
    tree: roots,
    treeMap: nodeMap,
  }
}

const getTableHeight = (height) => {
  if (store.state.rate > 1) {
    height = height * store.state.rate
  }
  return height
}

function extractLetters(str) {
  // 使用正则表达式匹配开头的英文字母
  const match = str.match(/^[a-zA-Z]+/)
  // 如果匹配成功，返回匹配到的字符串，否则返回空字符串
  return match ? match[0] : ''
}

const jumpSoucePage = (sn, id) => {
  let type = extractLetters(sn)
  let urlMapping = {
    PO: '/payableManage/payableDetail', //应付单
    DP: '/payableManage/depositPaymentDetail', //预付单
    PR: '/payableManage/paymentRequestDetail', //付款申请单
    PN: '/payableManage/paymentNoteDetail', //付款单
    RO: '/receivableManage/receivableDetail', //应收单
    RN: '/receivableManage/receiptNoteDetail', //收款单
    RW: '/receivableManage/writtenOffOrderList', //应收核销单
    JV: '/voucherManage/voucherDetail', //凭证详情
    ICB: '/capitalManage/capitalAccountInitDetail', //资金账户初始化
    ZCKP: '/asset/assetCardDetail', //资产卡片
    ZCZJ: '/asset/assetDepreciationDetail', //资产折旧
    ZCBG: '/asset/assetChangeDetail', //资产变更
    ZCJZ: '/asset/assetImpairmentDetail', //资产减值
    ZCQL: '/asset/assetCleanupDetail', //资产清理
  }
  let route = urlMapping[type]
  console.log('route: ', route)
  if (type === 'RW') {
    let router = getRouter()
    router.push(route)
    return
  }
  const parts = route.split('/')
  const view = parts[2]
  store.commit('addPane', {
    view: view + '/' + sn,
    name: 'detailSn',
    query: {
      id,
      sn,
    },
    route: route + `?id=${id}&sn=${sn}`,
  })
}

const goBack = (name, back = 0) => {
  let router = getRouter()
  if (back) {
    if (window.history.length > 1) {
      router.go(-1)
    } else {
      router.push({ name })
    }
    return
  }
  router.push({ name })
}

const generatePeriods = (startDate, endDate) => {
  const periods = []
  let currentDate = new Date(startDate)
  const endDateObj = new Date(endDate)
  while (currentDate <= endDateObj) {
    periods.push(currentDate.toISOString().slice(0, 7)) // 格式化为 YYYY-MM
    currentDate.setMonth(currentDate.getMonth() + 1) // 增加一个月
  }
  return periods
}

export {
  isEmptyObject,
  copy,
  getPermission,
  toFixedUp,
  getUnitRate,
  convertKeysToCamelCase,
  spaceToComma,
  logout,
  buildTree,
  buildTree2,
  getTableHeight,
  jumpSoucePage,
  goBack,
  generatePeriods,
}
