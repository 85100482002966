<template>
  <div class="pagination flex-ct between">
    <div class="pl10">
      <span class="text-link" v-if="showSelectAll" @click="$emit('selectAll')">
        {{ $t('选择全部') }}
      </span>
    </div>
    <a-pagination
      size="small"
      v-model="currentPage"
      :total="+totalCount"
      show-less-items
      show-size-changer
      show-quick-jumper
      :defaultPageSize="20"
      :pageSizeOptions="pageList"
      @showSizeChange="pageSizeChanged"
      :show-total="(total) => `${$t('共')} ${total} ${$t('条')} `"
    />
  </div>
</template>

<script>
import { pagination } from 'ant-design-vue'
export default {
  props: ['offset', 'length', 'pageNo', 'totalCount', 'pages', 'showSelectAll'],
  components: { 'a-pagination': pagination },
  data() {
    const pages = this.pages ? this.pages.split(',') : ['1', '10', '20', '50', '100', '200']
    return {
      pageList: pages,
      stop: false,
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNo || this.offset / this.length + 1
      },
      set(val) {
        if (this.stop) {
          this.stop = false
          return
        }
        this.$emit('paginate', {
          offset: this.length * (val - 1),
          length: this.length,
          pageNo: val,
          pageSize: this.length,
        })
      },
    },
  },
  methods: {
    pageSizeChanged(current, pageSize) {
      // console.log(this.$route.name);
      if (this.currentPage !== 1) {
        this.stop = true
      }
      this.$emit('paginate', {
        offset: 0,
        length: pageSize,
        pageNo: 1,
        pageSize,
      })
    },
  },
}
</script>

<style scoped>
.pagination {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
</style>
