import {
  scmWebEntry,
  inventoryWebEntry,
  wmshubWebEntry,
  shopWebEntry,
  goodsWebEntry,
  orderWebEntry,
} from '../config/config.js'

import router from '@/router.js'
import store from '@/store'
import actions from './actions.js'
// 作为管理应用的文件，需要导出在main.js挂载
const microApps = [
  /**
   * name: 微应用名称 - 具有唯一性
   * entry: 微应用入口 - 通过该地址加载微应用
   * activeRule: 微应用触发的路由规则 - 触发路由规则后将加载该微应用
   * container: 微应用挂载节点 - 微应用加载完成后将挂载在该节点上
   */
  {
    name: 'orderManage',
    entry: orderWebEntry,
    container: '#subapp-viewport',
    activeRule: '/orderManage',
  },
  //采购系统
  {
    name: 'purchase',
    entry: scmWebEntry,
    container: '#subapp-viewport',
    activeRule: '/purchase',
  },
  //库存系统
  {
    name: 'inventory_web',
    entry: inventoryWebEntry,
    container: '#subapp-viewport',
    activeRule: '/inventory_web',
  },
  {
    name: 'order',
    entry: wmshubWebEntry,
    container: '#subapp-viewport',
    activeRule: '/order',
  },
  //店铺设置
  {
    name: 'shop_web',
    entry: shopWebEntry,
    container: '#subapp-viewport',
    activeRule: '/shop_web',
  },
  //商品
  {
    name: 'goodsManage',
    entry: goodsWebEntry,
    container: '#subapp-viewport',
    activeRule: '/goodsManage',
  },
]

const apps = microApps.map((item) => {
  return {
    ...item,
    activeRule: window.__POWERED_BY_QIANKUN_PARENT__ ? `FMS${item.activeRule}` : item.activeRule,
    props: {
      parentRouter: router,
      routerBase: item.activeRule,
      getGlobalState: actions.getGlobalState,
      addPane: (data) => store.commit('addPane', data),
      store,
      priv: () => {
        return true
      },
      ...item.props,
    },
    loader: (loading) => {
      if (loading) {
        store.commit('setState', { dashboard_loading: true, loading_type: item.name })
      } else {
        store.commit('setState', { dashboard_loading: false })
      }
    },
  }
})

export default apps
