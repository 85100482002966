export default {
  subMerchantAppList(state) {
    return state.userInfo.subMerchantAppList
  },
  partyId: (state) => state.userSession?.currentBu?.id,
  currencyListMapping: (state) => listToMap(state.currencyList),
  currencySymbolMapping: (state) => listToMap(state.currencyList, 'currency_symbol'),
  documentTypeMapping: (state) => listToMap(state.document_type),
  payableOrderStatusMapping: (state) => listToMap(state.payable_order_status),
  payableOrderTypeMapping: (state) => listToMap(state.payable_order_type),
  initCapitalBalanceStatusMapping: (state) => listToMap(state.init_capital_balance_status),
  initAccountBalanceStatusMapping: (state) => listToMap(state.init_account_balance_status),
  invoiceTypeMapping: (state) => listToMap(state.invoice_type),
  invoiceBizTypeMapping: (state) => listToMap(state.invoice_biz_type),
  invoiceStatusMapping: (state) => listToMap(state.invoice_status),
  payableOrderInvoiceStatusMapping: (state) => listToMap(state.payable_order_invoice_status),
  paymentRequestStatusMapping: (state) => listToMap(state.payment_request_status),
  paymentRequestTypeMapping: (state) => listToMap(state.payment_request_type),
  paymentNoteStatusMapping: (state) => listToMap(state.payment_note_status),
  paymentNoteTypeMapping: (state) => listToMap(state.payment_note_type),
  depositPaymentTypeMapping: (state) => listToMap(state.deposit_payment_type),
  depositPaymentStatusMapping: (state) => listToMap(state.deposit_payment_status),
  sourceOrderTypeMapping: (state) => listToMap(state.source_order_type),
  bizOrderStatusMapping: (state) => listToMap(state.payable_order_biz_status),
  journalVoucherTypeMapping: (state) => listToMap(state.journal_voucher_type),
  journalVoucherStatusMapping: (state) => listToMap(state.journal_voucher_status),
  receivableOrderStatusMapping: (state) => listToMap(state.receivable_order_status),
  receivableOrderTypeMapping: (state) => listToMap(state.receivable_order_type),
  receiptNoteStatusMapping: (state) => listToMap(state.receipt_note_status),
  receiptNoteTypeMapping: (state) => listToMap(state.receipt_note_type),
  goodsTypeMapping: (state) => listToMap(state.goods_type),
  expenseTypeMapping: (state) => listToMap(state.expense_type),
  writtenOffTypeMapping: (state) => listToMap(state.written_off_type),
  assetCardStatusMapping: (state) => listToMap(state.asset_card_status),
  assetOperateStatusMapping: (state) => listToMap(state.asset_operate_status),
  bankCapitalTypeMapping: (state) => listToMap(state.bank_capital_type),
  capitalTypeMapping: (state) => listToMap(state.capital_type),
  accountPurposeMapping: (state) => listToMap(state.account_purpose),
  cashFlowTypeMapping: (state) => listToMap(state.cash_flow_type),
  fundingDirectionMapping: (state) => listToMap(state.funding_direction),
  balanceDirectionMapping: (state) => listToMap(state.balance_direction),
  contactTypeMapping: (state) => listToMap(state.contact_type),
}

function listToMap(list = [], label = 'name') {
  let obj = {}
  list.forEach((item) => {
    obj[item.code] = item[label]
  })
  return obj
}
