import Vue from 'vue'
import './public-path'
import App from './App.vue' // 根组件
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import './utils/rem'
import './utils/antd'
import './css/index.scss'
import breadcrumb from '@component/breadcrumb/index'
import PriceInput from '@component/priceInput'
import CommonQuerySelect from '@component/commonQuerySelect'
import CommonSelect from '@component/commonSelect'
import CommonTable from '@component/commonTable'
import OperateBtn from '@component/operateBtn'
import VoucherBtn from '@component/voucherBtn'
import FindRelatedDocBtn from '@component/findRelatedDocBtn'
import ModalBox from '@component/modalBox'
import { Modal } from 'ant-design-vue'
import actions from './qiankun/actions2'
import i18n from './utils/lang'
import i18nPlugin from './utils/i18n'
import { getCookie } from '@/common/cookie'
import { registerMicroApps, addErrorHandler, prefetchApps, start } from 'qiankun'
import apps from './qiankun/apps.js'
import { systemName } from './config/config.js'
// 注册全局过滤器
import * as filters from './common/filters' // global filters

Vue.use(i18nPlugin)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
  Vue.prototype['$' + key] = filters[key]
})

// 设置一个全局参数，让孙应用知道在哪个环境
window.__QIANKUN_PARENTNAME__ = systemName
window.document.title = systemName

if (systemName === 'FAST_BOOKS') {
  registerMicroApps(apps)
  // 预加载子应用
  start({
    prefetch: true,
  })
  prefetchApps([apps[0]])
}

Vue.component('breadcrumb', breadcrumb)
Vue.component('PriceInput', PriceInput)
Vue.component('CommonSelect', CommonSelect)
Vue.component('OperateBtn', OperateBtn)
Vue.component('VoucherBtn', VoucherBtn)
Vue.component('FindRelatedDocBtn', FindRelatedDocBtn)
Vue.component('CommonQuerySelect', CommonQuerySelect)
Vue.component('CommonTable', CommonTable)
Vue.component('ModalBox', ModalBox)

Vue.prototype.$isEmpty = (val) => {
  return val === null || val === undefined || val === ''
}
const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

let router = null
let instance = null
function render(props = {}) {
  const { container, erpLogout, erpJump, showAppDrawer } = props
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
    Vue.prototype.$erpLogout = erpLogout
    Vue.prototype.$erpJump = erpJump
    Vue.prototype.$showAppDrawer = showAppDrawer
  }
  router = new VueRouter({
    // 注意这里的name,最好不要写死，直接使用主应用传过来的name
    base: window.__POWERED_BY_QIANKUN__ ? `FMS` : '/',
    mode: 'history',
    routes,
  })

  router.beforeEach((to, from, next) => {
    if (to.name === 'login') {
      if (!getCookie('d_session_id')) {
        next()
      } else {
        next({ name: 'payableList' })
      }
    } else if (!getCookie('d_session_id')) {
      localStorage.setItem('pageCacheFullPath', to.fullPath)
      next({ name: 'login' })
      return
    }
    next()
  })

  Vue.use(VueRouter)
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export function getRouter() {
  return router
}

export async function bootstrap() {
  console.log('[vue2] fms app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
  router = null
}

// 添加错误处理函数
addErrorHandler((error) => {
  // 在这里对子应用加载错误进行处理
  console.error('子应用加载错误:', error)
  if (process.env.NODE_ENV === 'production') {
    Modal.error({
      title: i18n.t('页面出错了'),
      content: i18n.t('请尝试浏览器无痕浏览访问，仍不行请联系技术人员'),
    })
  }
})
