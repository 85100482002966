;(function (doc) {
  let docEl = doc.documentElement //获取根节点的html

  // 检测设备类型
  const ua = navigator.userAgent
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua)
  const isTablet = /iPad/i.test(ua) || (window.innerWidth <= 1024 && window.innerWidth > 768)
  const isPhone = isMobile || window.innerWidth <= 768

  // 如果既不是平板也不是手机，则使用默认字体大小
  if (!isTablet && !isPhone) {
    return
  }

  doc.addEventListener('DOMContentLoaded', recalc)
  window.addEventListener('resize', recalc)

  function recalc() {
    let width = docEl.clientWidth
    if (isPhone) {
      // 手机端使用更小的基准字体
      docEl.style.fontSize = 16 * (width / 320) + 'px'
    } else if (isTablet) {
      // 平板端使用稍大的基准字体
      docEl.style.fontSize = 18 * (width / 768) + 'px'
    }
  }
})(document)
