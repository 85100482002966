import i18n from './lang'

// 获取浏览器语言
export const getBrowserLang = () => {
  const lang = navigator.language || navigator.browserLanguage
  const lowLang = lang.toLowerCase()

  if (lowLang.includes('zh')) return 'zh'
  if (lowLang.includes('th')) return 'th'
  return 'en' // 默认英语
}

// 获取当前语言
export const getDefaultLang = () => {
  return localStorage.getItem('lang') || getBrowserLang()
}

export const t = (key, params = {}) => {
  const result = i18n.t(key, params)
  // 如果翻译不存在，返回 key
  return result
}

export const setLocale = (locale) => {
  i18n.locale = locale
  localStorage.setItem('lang', locale)
  // 设置 moment.js 的语言
  if (window.moment) {
    window.moment.locale(locale === 'zh' ? 'zh-cn' : locale)
  }
  // 设置 antd 的语言
  if (window.antd) {
    const antdLocales = {
      zh: 'zh_CN',
      en: 'en_US',
      th: 'th_TH',
    }
    window.antd.locales = antdLocales[locale]
  }
}

export const getLocale = () => {
  return i18n.locale
}

export default {
  install(Vue) {
    // 初始化默认语言
    const defaultLang = getDefaultLang()
    console.log('defaultLang: ', defaultLang)
    setLocale(defaultLang)

    // 添加实例方法
    Vue.prototype.$t = t
    Vue.prototype.$setLocale = setLocale
    Vue.prototype.$getLocale = getLocale
  },
}
